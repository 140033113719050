import React from 'react';
import detectIt from 'detect-it';
import cookie from 'react-cookies';
import ImageLink from '../../../core/Components/ImageLink';
import BindEnterAndSpaceToClick from '../../../core/Utils/BindEnterAndSpaceToClick';
import MessageBanner from './Components/MessageBanner/MessageBanner';
import CountryRedirect from './Components/CountryRedirect/CountryRedirect';
import CountrySelector from './Components/CountrySelector/CountrySelector';
import MobileHeader from './Components/MobileHeader/MobileHeader';
import TopNav from './Components/TopNav/TopNav';
import MobileToolbar from './Components/MobileToolbar/MobileToolbar';
import OnScreenNav from './Components/OnScreenNav/OnScreenNav';
import Search from './Components/Search/Search';
import ProfessionalsPopup from './Components/ProfessionalsPopup/ProfessionalsPopup';
import {defaults, types} from './Header.props';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			overlay: null,
			overlayClick: () => this.setOverlay(),
			sticky: false,
			isSearching: false,
			showCountryDropdown: false,
		};
		this.navRef = React.createRef();
	}

	componentDidMount() {
		const {fields, isProfessional} = this.props;
		window.addEventListener(
			'scroll',
			this.handleScroll,
			detectIt.passiveEvents ? {passive: true} : false,
		);

		this.setSessionCookie();

		if (isProfessional && !cookie.load('professional')) {
			document.body.style['overflow-y'] = 'hidden';
			document.body.style.height = '100vh';
			this.setState({overlayClick: () => this.closeProfessionalPopup(true)});
			this.setOverlay(<ProfessionalsPopup fields={fields} closePopup={this.closeProfessionalPopup}/>);
		}
	}

	componentWillUnmount() {
		window.removeEventListener(
			'scroll',
			this.handleScroll,
			detectIt.passiveEvents ? {passive: true} : false,
		);
	}

	setSessionCookie = () => {
		const session = cookie.load('cochlearSession');
		if(!session){
			//UUIDV4 function
			const sessionId = 'x-y-y-x'.replace(/[x,y]/g, c => {
				return Math.random().toString(16).substring(c === 'x' ? 7 : 4);
			});
			cookie.save('cochlearSession', sessionId, {path: '/'});
		}
	};

	handleScroll = () => {
		const scrollTarget = this.navRef.current;
		if (scrollTarget) {
			this.setState(prevState => {
				//ie can't usee scrollY or getBoundingClientRect().y, so we have fallbacks
				const boundY = scrollTarget.getBoundingClientRect().y;
				const y = window.scrollY || window.pageYOffset;
				return {
					sticky: prevState.sticky
						? y !== 0
						:  boundY ? boundY < -150 : window.pageYOffset > 150,
				};
			});
		}
	};

	setOverlay = (reactChild = false) => {
		this.setState(prevState => {
			return {
				overlay: reactChild,
				isSearching: reactChild ? prevState.isSearching : false,
			};
		});
	};

	toggleSearch = () => {
		this.setState(prevState => {
      this.props.closeMobileMenu();
			return {
				overlay: !prevState.isSearching,
				isSearching: !prevState.isSearching,
			};
		});
		this.props.setPageScrollable(true);
	};

	closeSearch = () => {
		this.setState({
			overlay: false,
			isSearching: false,
		});
		this.props.setPageScrollable(true);
	};

	closeProfessionalPopup = (redirect = false) => {
		if (redirect) {
			window.history.back();
		} else {
			document.body.style = null;
			this.setState({
				overlay: false,
				overlayClick: () => this.setOverlay(),
			});
		}
	};

	toggleCountrySelector = (shouldShow = null) => {
		this.setState(prevState => {
			const show = shouldShow === null ? !prevState.showCountryDropdown : shouldShow;
			this.props.setPageScrollable(!show);
			return {
				overlay: show ? (
					<CountrySelector
						fields={this.props.fields}
						toggleCountrySelector={() => this.toggleCountrySelector()}
					/>
				) : null,
				showCountryDropdown: show,
			};
		});
	};

	render() {
		const { overlay, overlayClick, isSearching, sticky } = this.state;
		const { fields, isProfessional, closeMobileMenu, toggleMobileMenu, showMobileMenu } = this.props;
    const { messageBannerText } = fields;
    const toolbarClass = (searching, mobileMenuOpen) => {
      if (searching) {
        return ''
      }
      return mobileMenuOpen ? 'mobile-menu' : 'mobile-hidden'
    }
		return (
			<header className={`global-header${sticky ? ' is-sticky-header' : ''}`}>
				<ul className="accessibility-links" tabIndex="-1">
					<li>
						<a href="#article" tabIndex={showMobileMenu ? -1 : 0}>{fields.jumpToContentText.value}</a>
            <a href="#nav" className="jump-nav-desktop" tabIndex={showMobileMenu ? -1 : 0}>{fields.jumpToNavText.value}</a>
            <a href="#mobile-toolbar-nav" className="jump-nav-mobile" tabIndex={showMobileMenu ? -1 : 0}>{fields.jumpToNavText.value}</a>
					</li>
				</ul>
				{messageBannerText.value && <MessageBanner field={messageBannerText} />}
				<CountryRedirect
					fields={fields}
					toggleCountrySelector={this.toggleCountrySelector}
        />

        <MobileToolbar
					fields={fields}
					handleMenuClick={() =>
						this.setState(prevState => {
              toggleMobileMenu();
							return {
								isSearching: false,
								overlay: false,
							};
						})
          }
          showMobileMenu={showMobileMenu}
					handleSearchClick={this.toggleSearch}
				/>

				<nav
					id="nav"
					ref={this.navRef}
					className={`nav-onscreen js-onscreen ${toolbarClass(isSearching, showMobileMenu)} ${
						sticky ? 'is-sticky' : ''
					}`}
				>
					<TopNav
						fields={fields}
						toggleCountrySelector={this.toggleCountrySelector}
					/>
					<div className={'l-padding'}>
						{showMobileMenu ? (
							<React.Fragment>
								<OnScreenNav
									fields={fields}
									isProfessional={isProfessional}
									handleMobileClose={() => closeMobileMenu()}
									toggleCountrySelector={this.toggleCountrySelector}
                  closeSearch={this.closeSearch}
                  showMobileMenu={showMobileMenu}
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								<OnScreenNav
									fields={fields}
									searchOpen={isSearching}
									closeSearch={this.closeSearch}
								/>
								<button
									className={`link-icon search-toggle ${isSearching ? 'active' : ''}`}
									type="button"
									aria-label="Toggle search"
									data-popover-id="global-search"
									aria-expanded="false"
									onClick={this.toggleSearch}
								/>
							</React.Fragment>
						)}
					</div>
					{isSearching && <Search fields={this.props.fields} />}
				</nav>

				<MobileHeader
					fields={fields}
          showMobileMenu={showMobileMenu}
				/>

				{overlay && (
					<React.Fragment>
						<div
							onClick={overlayClick}
							onKeyDown={BindEnterAndSpaceToClick}
							role="button"
							tabIndex="0"
							className="offscreen-background is-expanded"
						/>
						{overlay}
					</React.Fragment>
				)}
			</header>
		);
	}
}

Header.defaultProps = defaults;
Header.propTypes = types;

export default Header;
