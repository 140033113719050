import {DefaultProps, PropTypes} from '../../../core/Utils/SitecoreFields';

export const defaults = DefaultProps.SitecoreItem.inject({
	additionalData: {},
	desktopLogo: DefaultProps.Image,
	logoLink: DefaultProps.Link,
	mobileLogo: DefaultProps.Image,
	topLogo: DefaultProps.Image,
	topNavList: DefaultProps.Multilist,
	homeLink: DefaultProps.Link,
	professionalsLInk: DefaultProps.Link,
	loginLink: DefaultProps.Link,
	rootItem: {},
	menuLinkText: DefaultProps.SingleLineText,
	menuLinkDescription: DefaultProps.SingleLineText,
	messageBannerText: DefaultProps.SingleLineText,
	regionList: DefaultProps.Multilist,
	currentCountry: {
		fields: {
			text: DefaultProps.SingleLineText,
			link: DefaultProps.Link,
			icon: DefaultProps.Image,
			languageList: DefaultProps.Multilist,
			key: DefaultProps.SingleLineText,
		},
	},
	countrySelectTitle: DefaultProps.SingleLineText,
	countryRedirectText: DefaultProps.SingleLineText,
	countryRedirectRegionText: DefaultProps.SingleLineText,
	countryRedirectButton: DefaultProps.SingleLineText,
	countryRedirectClose: DefaultProps.SingleLineText,
	languageOtherText: DefaultProps.SingleLineText,
	languageOtherIcon: DefaultProps.Image,
	menuText: DefaultProps.SingleLineText,
	contactLink: DefaultProps.Link,
	clinicFinderLink: DefaultProps.Link,
	jdStoreLink: DefaultProps.Link,
	searchText: DefaultProps.SingleLineText,
	additionalList: DefaultProps.Multilist,
	closeText: DefaultProps.SingleLineText,
	backText: DefaultProps.SingleLineText,
	searchLink: DefaultProps.Link,
	searchPlaceholder: DefaultProps.SingleLineText,
	searchDescription: DefaultProps.SingleLineText,
	jumpToNavText: DefaultProps.SingleLineText,
	jumpToContentText: DefaultProps.SingleLineText,
	languageSelectAria: DefaultProps.SingleLineText,
	countrySelectAria: DefaultProps.SingleLineText,
	countrySelectClose: DefaultProps.SingleLineText,
	telephoneIcon: DefaultProps.Image,
	telephoneNum: DefaultProps.SingleLineText,
	telephoneTitle: DefaultProps.SingleLineText,
	qRcodeTitle: DefaultProps.SingleLineText,
	qRcodeIcon: DefaultProps.Image,
	qRcodeBigIcon: DefaultProps.Image,
	jdIcon:DefaultProps.Image,
	jdLink:DefaultProps.Link,
});

export const types = PropTypes.SitecoreItem.inject({
	additionData: PropTypes.shape({
		data: PropTypes.shape({
			item: PropTypes.shape({
				...PropTypes.GraphQL.basePage,
				hideFromNavigation: PropTypes.shape({
					boolValue: PropTypes.boolean,
				}),
				hideFromBreadcrumbs: PropTypes.shape({
					boolValue: PropTypes.boolean,
				}),
				...PropTypes.GraphQL.basePage,
				hideFromSitemap: PropTypes.shape({
					boolValue: PropTypes.boolean,
				}),
				children: PropTypes.arrayOf(PropTypes.shape({
					...PropTypes.GraphQL.basePage,
					hideFromNavigation: PropTypes.shape({
						boolValue: PropTypes.boolean,
					}),
					hideFromBreadcrumbs: PropTypes.shape({
						boolValue: PropTypes.boolean,
					}),
					children: PropTypes.arrayOf(PropTypes.shape({
						...PropTypes.GraphQL.basePage,
						hideFromNavigation: PropTypes.shape({
							boolValue: PropTypes.boolean,
						}),
						hideFromBreadcrumbs: PropTypes.shape({
							boolValue: PropTypes.boolean,
						}),
						children: PropTypes.arrayOf(PropTypes.shape({
							...PropTypes.GraphQL.basePage,
							hideFromNavigation: PropTypes.shape({
								boolValue: PropTypes.boolean,
							}),
							hideFromBreadcrumbs: PropTypes.shape({
								boolValue: PropTypes.boolean,
							}),
						})),
					})),
				})),
			}),
		}),
	}),
	desktopLogo: PropTypes.Image,
	logoLink: PropTypes.Link,
	mobileLogo: PropTypes.Image,
	topLogo: PropTypes.Image,
	topNavList: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	homeLink: PropTypes.Link,
	professionalsLInk: PropTypes.Link,
	loginLink: PropTypes.Link,
	rootItem: PropTypes.any,
	menuLinkText: PropTypes.SingleLineText,
	menuLinkDescription: PropTypes.SingleLineText,
	messageBannerText: PropTypes.SingleLineText,
	regionList: PropTypes.Multilist.inject({
		heading: PropTypes.SingleLineText,
		countryList: PropTypes.Multilist.inject({
			text: PropTypes.SingleLineText,
			link: PropTypes.Link,
			icon: PropTypes.Image,
			languageList: PropTypes.Multilist.inject({
				key: PropTypes.SingleLineText,
				sitecoreKey: PropTypes.SingleLineText,
				text: PropTypes.SingleLineText,
			}),
			key: PropTypes.SingleLineText,
		}),
	}),
	currentCountry: PropTypes.SitecoreItem.inject({
		text: PropTypes.SingleLineText,
		link: PropTypes.Link,
		icon: PropTypes.Image,
		languageList: PropTypes.Multilist.inject({
			key: PropTypes.SingleLineText,
			sitecoreKey: PropTypes.SingleLineText,
			text: PropTypes.SingleLineText,
		}),
		key: PropTypes.SingleLineText,
	}),
	countrySelectTitle: PropTypes.SingleLineText,
	countryRedirectText: PropTypes.SingleLineText,
	countryRedirectRegionText: PropTypes.SingleLineText,
	countryRedirectButton: PropTypes.SingleLineText,
	countryRedirectClose: PropTypes.SingleLineText,
	languageOtherText: PropTypes.SingleLineText,
	languageOtherIcon: PropTypes.Image,
	menuText: PropTypes.SingleLineText,
	contactLink: PropTypes.Link,
	clinicFinderLink: PropTypes.Link,
	jdStoreLink: PropTypes.Link,
	searchText: PropTypes.SingleLineText,
	additionalList: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	closeText: PropTypes.SingleLineText,
	backText: PropTypes.SingleLineText,
	searchLink: PropTypes.Link,
	searchPlaceholder: PropTypes.SingleLineText,
	searchDescription: PropTypes.SingleLineText,
	jumpToNavText: PropTypes.SingleLineText,
	jumpToContentText: PropTypes.SingleLineText,
	languageSelectAria: PropTypes.SingleLineText,
	countrySelectAria: PropTypes.SingleLineText,
	countrySelectClose: PropTypes.SingleLineText,
	telephoneIcon: PropTypes.Image,
	telephoneNum: PropTypes.SingleLineText,
	telephoneTitle: PropTypes.SingleLineText,
	qRcodeTitle: PropTypes.SingleLineText,
	qRcodeIcon: PropTypes.Image,
	qRcodeBigIcon: PropTypes.Image,
	jdIcon:PropTypes.Image,
	jdLink:PropTypes.Link,
});
