import React from 'react';
import {Text} from '@sitecore-jss/sitecore-jss-react';

const CountrySelector = ({fields, toggleCountrySelector}) => {
	return (
		<div className="country-select-nav">
			<div className="country-select-title">
				<div className="l-padding">
					<span aria-label={fields.countrySelectAria.value} className="h1">
						<Text field={fields.countrySelectTitle} />
					</span>
					<button
						className="country-exit"
						type="button"
						onClick={toggleCountrySelector}
						aria-label={fields.countrySelectClose.value}
					/>
				</div>
			</div>
			<div className="country-select-list l-padding">
				{fields.regionList.map(region => {
					return (
						<React.Fragment key={region.fields.heading.value}>
							<h3>{region.fields.heading.value}</h3>
							<ul>
								{region.fields.countryList.map(country => {
									return country.fields.languageList.map(language => {
										let link = country.fields.link.value.href;

										if (country.fields.languageList.length > 1) {
											link = `${link}/${language.fields.key.value}`;
										}
										return (
											<li
												className="country-optimisation"
												key={`${country.fields.key.value}-${language.fields.key.value}`}
											>
												<a href={link}>
													<img
														src={country.fields.icon.value.src}
														alt={country.fields.icon.value.alt}
														className="region-flag"
													/>
													{country.fields.text.value}
													<span className="language">({language.fields.text.value})</span>
												</a>
											</li>
										);
									});
								})}
							</ul>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default CountrySelector;
