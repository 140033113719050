import React from 'react';
import {Text, Link} from '@sitecore-jss/sitecore-jss-react';

const MobileToolbar = ({fields, handleMenuClick, handleSearchClick, isSearching, showMobileMenu}) => {
	return (
		<nav id="mobile-toolbar-nav" className="mobile-toolbar">
			<ul className="cf">
				<li>
					<button type="button" onClick={handleMenuClick} className="menu">
						<Text field={fields.menuText} />
					</button>
				</li>
				<li>
					<Link field={fields.contactLink} className="contact" tabIndex={showMobileMenu ? -1 : 0 }/>
				</li>
				<li>
					<Link field={fields.jdStoreLink} className="store" tabIndex={showMobileMenu ? -1 : 0 } />
				</li>
				<li>
					<button
						type="button"
						onClick={handleSearchClick}
            className={`search ${isSearching ? 'active' : ''}`}
            tabIndex={showMobileMenu ? -1 : 0 }
					>
						<Text field={fields.searchText} />
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default MobileToolbar;
