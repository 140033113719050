import React from 'react';
import ImageLink from '../../../../../core/Components/ImageLink';
import {Text, Image ,RichText ,Link} from '@sitecore-jss/sitecore-jss-react';

const TopNav = ({fields, toggleCountrySelector}) => (
	<div className="top-nav">
		<div className="l-padding topLinkOut">
			<div className="container topLink">
				<ImageLink imageField={fields.topLogo} linkField={fields.logoLink}/>
			</div>
			<div className="nav-selector">
				<div className="tell">
					<Image field={fields.telephoneIcon}/>
					<p>
						<Text field={fields.telephoneTitle} tag="span" />
						<Text field={fields.telephoneNum} tag="span" className="tell-num"/>
					</p>
				</div>
				<div className="QRcode clearfix">
					<div className="codeDefault">
						<Image field={fields.qRcodeIcon} className="smsllQRcode"/>
						<div className="codeBig">
							<Image field={fields.qRcodeBigIcon}/>
						</div>
					</div>
					<RichText field={fields.qRcodeTitle} tag="p"/>
				</div>
				<div className="jd">
					<ImageLink imageField={fields.jdIcon} linkField={fields.jdLink}/>
				</div>
			</div>
		</div>
	</div>
);

export default TopNav;
