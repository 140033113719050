import React from 'react';
import cookie from 'react-cookies';
import {Text} from '@sitecore-jss/sitecore-jss-react';

const ProfessionalsPopup = ({fields, closePopup}) => {

	const handleAccept = () => {
		const expiryDate = new Date();
		expiryDate.setMonth(expiryDate.getMonth() + 1);
		cookie.save('professional', true, {path: '/', expires: expiryDate});
		closePopup();
	};

	return (
		<div className="professionals-popup">
			<button
				type="button"
				className="close-btn"
				onClick={() => closePopup(true)}
				aria-label={fields.popupCloseAria.value}
			/>
			<Text field={fields.popupTitle} tag="h2" />
			<Text field={fields.popupDescription} tag="p" />
			<button type="button" className="cta-button" onClick={handleAccept}>
				<Text field={fields.popupAccept} />
			</button>
			<button type="button" className="cta-button secondary" onClick={() => closePopup(true)}>
				<Text field={fields.popupDecline} />
			</button>
		</div>
	);
};

export default ProfessionalsPopup;
